<template>
<c-box>
  <Header />

  <c-box max-w="1200" mx="auto" mt="30px" :px="['20px', '0']">
    <Breadcrumb :items="breadcrumbItems" />
    <hr v-chakra mt="20px">

    <c-flex my="30px">
      <Navigation />
      <c-box :ml="['0', '20px']" w="full">
        <router-view v-if="hasProgram" />
        <c-box v-else>
          <EmptyState title="Anda tidak memiliki program aktif" />
        </c-box>
      </c-box>
    </c-flex>
  </c-box>
  <Footer />
</c-box>
</template>

<script>
import Header from "@/components/header/index.vue";
import Breadcrumb from "@/components/breadcrumb";
import Navigation from "./navigation.vue";
import Footer from "@/components/Footer";
import EmptyState from "@/views/meal-plan/ahli-gizi/empty-state.vue";

export default {
  name: "index.vue",
  components: {
    Footer,
    Breadcrumb,
    Header,
    Navigation,
    EmptyState,
  },
  data() {
    return {
      hasProgram: false,
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        { label: "Beranda", isCurrent: false, href: '#/' },
        { label: "Progress & Tracker", isCurrent: true },
      ];
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch("clientProgressTracker/getProgressData")
      this.hasProgram = true
    } catch (err) {
      let msg = err.response.data?.message;
      if (msg.includes("tidak memiliki program aktif")) {
        this.hasProgram = false;
        return
      }
    }
  },
}
</script>

<style scoped>

</style>
